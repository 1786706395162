<template>
  <div class="container">
    <div class="usable-card">
      <div class="title">
        <div class="left">可用次卡({{ CardLsit.length || '0'}})</div>
        <div class="right el-icon-plus" @click="payCard">购买卡次</div>
      </div>
      <el-row class="content" :gutter="10">
        <el-col :span="rowCol" v-for="(item,i) in CardLsit" :key="i">
          <div class="card cursor">
            <div class="card-top">
              <div class="card-title">
                <div class="name">{{  item.storecard_id.name}}</div>
                <div class="price"><b><span>{{ item.num }}/{{ item.storecard_id.cardnum }}</span>次</b></div>
              </div>
              <div class="card-info">
                项目：{{ item.storecard_id.server_ids }}
              </div>
            </div>
            <div class="card-btm">
              <div class="card-type">
                有效期至：{{ item.endtime | timeform(false) }}
              </div>
              <div class="stepper" @click="LookInfo(item)">
                查看详情<span class="el-icon-arrow-right"></span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <empty title="暂无卡次" v-if="CardLsit.length == 0"></empty>
    </div>
    <div class="failure-card usable-card">
      <div class="title">
        <div class="left">失效次卡</div>
      </div>
      <el-row class="content" :gutter="10">
        <el-col :span="rowCol" v-for="(item,i) in NoCardLsit" :key="i">
          <div class="card cursor">
            <div class="card-top">
              <div class="card-title">
                <div class="name">{{  item.storecard_id.name}} <span></span></div>
                <div class="price"><b><span>{{ item.num }}/{{ item.storecard_id.cardnum }}</span>次</b></div>
              </div>
              <div class="card-info">
                项目：{{ item.storecard_id.server_ids }}
              </div>
            </div>
            <div class="card-btm">
              <div class="card-type">
                有效期至：{{ item.endtime | timeform(false) }}
              </div>
              <!-- <div class="stepper" @click="backCardDetailShow=true">
                查看详情<span class="el-icon-arrow-right"></span>
              </div> -->
            </div>
          </div>
        </el-col>
      </el-row>
      <empty title="暂无卡次" v-if="NoCardLsit.length == 0"></empty>
    </div>
    <!-- 次卡详情 -->
    <el-dialog :visible.sync="cardDetailShow" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          次卡详情
        </div>
      </template>
      <el-container style="height:500px;background-color:#fff;" v-if="lookitem.storecard_id">
        <el-header class="dia-header">
          <div class="card cursor">
            <div class="card-top">
              <div class="card-title">
                <div class="name">{{ lookitem.storecard_id.name }}</div>
                <div class="price"><b><span>{{ lookitem.num }}/{{ lookitem.storecard_id.cardnum }}</span>次</b></div>
              </div>
              <div class="card-info">
                项目：{{ lookitem.storecard_id.server_ids }}
              </div>
              <div class="time">
                <!-- <span>时段：周二、周三 14:00-18:00</span> -->
                <span class="btn" @click="backCardShow=true">退卡</span>
              </div>
            </div>
            <div class="card-btm">
              <div class="card-type">
                有效期至：{{ lookitem.endtime | timeform(false) }}
              </div>
            </div>
          </div>
        </el-header>
        <el-main>
          <div class="dia-body">
            <div class="b-title el-icon-document">
              消费记录
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">使用时间</div>
                <div class="th">使用次数</div>
                <div class="th">账单编号</div>
                <!-- <div class="th">操作</div> -->
              </div>
              <div class="tbody" v-for="(item,i) in lookitem.shiyong" :key="i">
                <div class="td">{{ item.paytime | timeform(false) }}</div>
                <div class="td">{{ item.num }}</div>
                <div class="td">{{ item.ordernum }}</div>
                <!-- <div class="td" @click="lookBills">查看账单</div> -->
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-dialog>
    <!-- 退卡 -->
    <el-dialog class="back-dialog" :visible.sync="backCardShow" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          退卡
        </div>
      </template>
      <el-container style="height:550px;background-color:#fff;">
        <el-header class="dia-header">
          <div class="card cursor">
            <div class="card-top">
              <div class="card-title">
                <div class="name">美容基础护理</div>
                <div class="price"><b><span>18/20</span>次</b></div>
              </div>
              <div class="card-info">
                项目：深层补水护理、动力SPA补水、美白去黄护理
              </div>
            </div>
            <div class="card-btm">
              <div class="card-type">
                有效期至：2020.12.30
              </div>
            </div>
          </div>
        </el-header>
        <el-main>
          <div class="dia-body">
            <div class="b-title el-icon-document">
              退卡信息
            </div>
            <div class="cell">
              <div class="cell-item">
                <div class="c-left">购买金额</div>
                <div class="c-right">¥99.00</div>
              </div>
              <div class="cell-item">
                <div class="c-left">有效期至</div>
                <div class="c-right">2021.12.30</div>
              </div>
              <div class="cell-item">
                <div class="c-left">总次数</div>
                <div class="c-right">20</div>
              </div>
              <div class="cell-item">
                <div class="c-left">购买金额</div>
                <div class="c-right">2</div>
              </div>
              <div class="cell-item">
                <div class="c-left">购买金额</div>
                <div class="c-right">18</div>
              </div>
              <div class="cell-item">
                <div class="c-left">购买金额</div>
                <div class="c-right"><b>¥69.00</b></div>
              </div>
              <div class="cell-item">
                <div class="c-left">购买金额</div>
                <div class="c-right">
                  <div class="edit el-icon-edit-outline" @click="backCardPriceShow=true">
                    修改
                  </div>
                  <b class="red">¥69.00</b>
                </div>
              </div>
            </div>
            <div class="btn">
              <el-button type="primary" @click="confirmBackCardShow=true">确认退卡</el-button>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-dialog>
    <!-- 退卡金额修改 -->
    <el-dialog class="dialog1" :visible.sync="backCardPriceShow" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          退卡
        </div>
      </template>
      <el-alert class="alert" title="实退金额不能大于账单总额" type="warning" show-icon :closable="false">
      </el-alert>
      <div class="cell">
        <div class="left">应退金额</div>
        <div class="right"><b>¥69.00</b></div>
      </div>
      <el-input v-model="backCardPrice" prefix-icon="el-icon-mobile-phone" placeholder="请输入实退金额"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="backCardPriceShow = false">取 消</el-button>
        <el-button type="primary" @click="backCardPriceShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 退卡成功 -->
    <el-dialog class="dialog1 confirm-dialog" :visible.sync="confirmBackCardShow" width="40%"
      :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          退卡成功
        </div>
      </template>
      <div class="top">
        <div class="el-icon-circle-check"></div>
        <div class="text1">退卡成功</div>
        <div class="text2">共退款¥69.00，已退回至原支付渠道</div>
      </div>

      <div class="btn">
        <el-button type="primary" round @click="handleClose">我知道了</el-button>
        <el-button round @click="confirmBackCardShow = false,backCardShow= false,backCardDetailShow=true">查看次卡信息
        </el-button>
      </div>
    </el-dialog>
    <!-- 退卡失败 -->
    <el-dialog class="dialog1 confirm-dialog" :visible.sync="cancelBackCardShow" width="40%"
      :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          退卡失败
        </div>
      </template>
      <div class="top">
        <div class="el-icon-circle-close"></div>
        <div class="text1">退卡失败</div>
        <div class="text2">您可以尝试重新退卡</div>
      </div>

      <div class="btn">
        <el-button type="primary" round @click="cancelBackCardShow = false,backCardShow=true">重新退卡</el-button>
        <el-button round @click="cancelBackCardShow = false">不想退了</el-button>
      </div>
    </el-dialog>
    <!-- 失效次卡 -->
    <el-dialog class="back-detail-dialog" :visible.sync="backCardDetailShow" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          次卡详情
        </div>
      </template>
      <el-container style="height:500px;background-color:#fff;">
        <el-header class="dia-header">
          <div class="card cursor">
            <div class="card-top">
              <div class="card-title">
                <div class="name">美容基础护理 <span>已退卡</span> </div>
                <div class="price"><b><span>18/20</span>次</b></div>
              </div>
              <div class="card-info">
                项目：深层补水护理、动力SPA补水、美白去黄护理
              </div>
              <div class="time">
                <span>时段：周二、周三 14:00-18:00</span>
                <span class="btn" @click="payCard">重新购买</span>
              </div>
            </div>
            <div class="card-btm">
              <div class="card-type">
                有效期至：2020.12.30
              </div>
            </div>
          </div>
        </el-header>
        <el-main>
          <div class="dia-body">
            <div class="b-title el-icon-document">
              消费记录
            </div>
            <template>
              <div class="table" v-if="false">
                <div class="thead">
                  <div class="th">使用时间</div>
                  <div class="th">使用次数</div>
                  <div class="th">账单编号</div>
                  <div class="th">操作</div>
                </div>
                <div class="tbody" v-for="(item,i) in 20" :key="i">
                  <div class="td">2021/02/01 12:00</div>
                  <div class="td">2</div>
                  <div class="td">202102016600</div>
                  <div class="td" @click="lookBills">查看账单</div>
                </div>
              </div>
              <div v-else class="null">
                <img src="@/assets/images/null.png" alt="">
                <div class="text">暂无相关消费记录</div>
              </div>
            </template>
          </div>
        </el-main>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        cardDetailShow: false,
        backCardShow: false,
        backCardPriceShow: false,
        backCardPrice: '',
        confirmBackCardShow: false,
        cancelBackCardShow: false,
        backCardDetailShow: false,
        CardLsit:[],//可用卡卷
        NoCardLsit:[],//不可用卡卷
        lookitem:{
          storecard_id:false
        },//当前查看的优惠券
      }
    },
    computed:{
      ...mapState(['vipuser'])
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          console.log(this.rowCol);
          this.rowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
        } else if (val >= 830) {
          this.rowCol = 12
        } else if (val <= 830) {
          this.rowCol = 12
        }
      }
    },
    created () {
      this.getCardList()
      this.getNoCardList()
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (that.innerWidth >= 1150) {
        this.rowCol = 8
      } else if (that.innerWidth >= 830) {
        this.rowCol = 12
      } else if (that.innerWidth <= 830) {
        this.rowCol = 12
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
    },
    methods: {
      LookInfo(item){
        this.lookitem = item
        this.cardDetailShow = true
      },
      //获取可用卡次列表
      getCardList(){
        this.$http.post('api/store/storeuser/usercardlist',{
          user_id:this.vipuser.id,
          type:1
        }).then(res =>{
          this.CardLsit = res.data.data
          if(this.CardLsit[0]){
            this.lookitem = this.CardLsit[0]
          }
        })
      },
      //获取可用卡次列表
      getNoCardList(){
        this.$http.post('api/store/storeuser/usercardlist',{
          user_id:this.vipuser.id,
          type:2
        }).then(res =>{
          this.NoCardLsit = res.data.data
        })
      },
      //购买卡
      payCard() {
        this.$router.push({
          name: 'SellCardList'
        })
      },
      // 查看账单
      lookBills() {
        this.$router.push({
          name: 'ServiceBills'
        })
        window.sessionStorage.setItem('reservationPath', '/meal-card')
      },
      handleClose() {
        this.cardDetailShow = false
        this.backCardShow = false
        this.backCardPriceShow = false
        this.confirmBackCardShow = false
        this.cancelBackCardShow = false
        this.backCardDetailShow = false
      }
    },
  }

</script>

<style lang="less" scoped>
  .usable-card {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 50px;
      color: #666;

      .left {
        font-weight: bold;
      }

      .right {
        color: #409eff;
        font-size: 12px;
        font-weight: bold;

        &::before {
          margin-right: 5px;
        }
      }
    }

    .content {
      padding: 0 10px 10px;

      .card {
        border: 3px solid #73a0fa;
        margin: 10px 0;
        border-bottom: none;

        .card-top {
          padding: 10px;


          .card-title,
          .card-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .card-title {
            .name {
              font-size: 14px;
              font-weight: bold;
            }

            .price {
              font-size: 12px;

              b {
                span {
                  font-size: 14px;
                  color: #ff9d0b;
                }
              }
            }
          }

          .card-info {
            font-size: 12px;
            color: #999;
            margin: 10px 0 15px;
          }

        }

        .card-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #73a0fa;
          font-weight: bold;
          color: #fff;
          padding: 10px;
          border-bottom: 3px dashed #fff;
          font-size: 12px;

        }
      }
    }
  }

  .failure-card {
    color: #666;

    .content {
      .card {
        border: 3px solid #ccc;
        border-bottom: none;

        .card-top {
          .card-title {
            .name {
              span {
                display: inline-block;
                background-color: #ccc;
                color: #f9f9f9;
                font-size: 12px;
                padding: 2px 8px;
                border-radius: 30px;
              }
            }

            .price {
              font-size: 12px;

              b {
                span {
                  font-size: 14px;
                  color: #ccc;
                }
              }
            }
          }
        }

        .card-btm {
          background-color: #ccc;
        }
      }
    }
  }

  /deep/ .el-dialog {
    border-radius: 10px;
    min-width: 400px;

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        top: 14px;
      }

    }

    .dia-title {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 0;
      text-align: center;
    }

    .el-dialog__body {
      height: 500px;
      padding: 10px;
    }

    .el-header {
      height: 170px !important;
      padding: 0;

      .card {
        border: 3px solid #73a0fa;
        margin: 10px 0;
        border-bottom: none;

        .card-top {
          padding: 10px;


          .card-title,
          .card-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .card-title {
            .name {
              font-size: 14px;
              font-weight: bold;
            }

            .price {
              font-size: 12px;

              b {
                span {
                  font-size: 14px;
                  color: #ff9d0b;
                }
              }
            }
          }

          .card-info,
          .time {
            font-size: 12px;
            color: #999;
            margin: 10px 0 15px;
          }

          .time {
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn {
              display: inline-block;
              width: 70px;
              line-height: 26px;
              text-align: center;
              color: #fff;
              background-color: #f56c6c;
              border-radius: 30px;
            }
          }


        }

        .card-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #73a0fa;
          font-weight: bold;
          color: #fff;
          padding: 10px;
          border-bottom: 3px dashed #fff;


          .stepper {

            /deep/ .el-input-number {
              width: 100px;
              height: 24px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .el-input-number__decrease,
              .el-input-number__increase {
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                z-index: 1;
                line-height: 20px;
                border-radius: 100%;
                border: 2px solid #fff;
                background-color: #73a0fa;
                color: #fff;
                font-weight: bold;
              }

              .el-input {
                width: 100px;
                height: 34px;

                .el-input__inner {
                  height: 24px;
                  line-height: 24px;
                  padding: 0 15px;
                  border-color: #73a0fa;
                  background-color: #73a0fa;
                  color: #fff;
                  font-weight: bold;
                  border: none;
                }
              }
            }
          }
        }
      }
    }

    .el-main {
      padding: 0 !important;
    }

    .dia-body {
      .b-title {
        font-weight: bold;
        margin: 10px 0 15px;

        &::before {
          font-weight: normal;
        }
      }

      .table {

        .thead,
        .tbody {
          display: flex;
          justify-content: space-around;
          align-items: center;



          .th,
          .td {
            text-align: center;
            line-height: 40px;
            font-size: 12px;

            &:nth-child(1),
            &:nth-child(3) {
              width: 30%;
            }

            &:nth-child(2),
            &:nth-child(4) {
              width: 20%;
            }
          }

          .td {
            &:nth-child(4) {
              color: #409eff;
              border: 1px solid #409eff;
              line-height: 22px;
              border-radius: 36px;
            }
          }
        }

        .thead {
          background-color: #f5f5f5;
        }

        .tbody {
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .cell {
        .cell-item {
          display: flex;
          justify-content: space-between;
          line-height: 40px;
          border-bottom: 1px solid #f2f2f2;
          padding: 0 10px;

          .c-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .edit {
              border: 1px solid #ff9900;
              color: #ff9900;
              font-size: 12px;
              line-height: 24px;
              width: 70px;
              text-align: center;
              border-radius: 30px;
              margin-right: 5px;
            }

            .red {
              color: #f56c6c;
            }
          }
        }
      }

      .btn {
        margin-top: 10px;

        .el-button {
          width: 100%;
        }
      }
    }

  }

  .dialog1 {
    /deep/ .el-dialog__body {
      height: auto;
      padding: 10px;
    }

    .alert {
      margin-bottom: 10px;
    }

    .cell {
      display: flex;
      justify-content: space-between;
      line-height: 40px;

      .right {
        b {
          color: #f56c6c;
        }
      }
    }
  }

  .confirm-dialog {
    .top {
      text-align: center;
      margin-top: 20px;

      .el-icon-circle-check {
        font-size: 60px;
        color: #19be6b;
      }

      .el-icon-circle-close {
        font-size: 60px;
        color: #f56c6c;
      }

      .text1 {
        margin-top: 15px;
        font-weight: bold;
      }

      .text2 {
        font-size: 12px;
        margin-top: 10px;
        color: #999;
      }
    }

    .btn {
      .el-button {
        width: 100%;
        margin: 0;
        margin-top: 15px;
      }
    }
  }

  .back-detail-dialog {
    .dia-header {
      .card {
        border: 3px solid #ccc;
        border-bottom: none;

        .card-top {
          .card-title {
            .name {
              span {
                display: inline-block;
                background-color: #ccc;
                color: #f9f9f9;
                font-size: 12px;
                padding: 2px 8px;
                border-radius: 30px;
                margin-left: 10px;
              }
            }

            .price {
              font-size: 12px;

              b {
                span {
                  font-size: 14px;
                  color: #ccc;
                }
              }
            }
          }
        }

        .card-btm {
          background-color: #ccc;
        }
      }
    }


    .time {
      .btn {
        background-color: #ff9900 !important;
      }
    }

    .null {
      text-align: center;

      img {
        width: 60%;
        margin-top: 30px;
      }

      .text {
        font-size: 14px;
        color: #999;
        margin-top: 15px;
      }
    }
  }

  /deep/ .back-dialog .el-dialog {
    .el-dialog__body {
      height: 550px !important;
      padding: 10px;
    }
  }

</style>
